<script>
import BillingFilter from "@/components/BillingFilter.vue";
import BillingTable from "@/components/BillingTable.vue";
import Loader from "vue-element-loading";
import api from "@/api";

export default {
  name: "BillingPaid",
  props: ["spaces"],
  components: {
    BillingFilter,
    BillingTable,
    Loader,
  },
  data: function () {
    return {
      loading: false,
      payments: [],
      page_no: 1,
      current_filters: null,
      has_more: true,
    };
  },
  methods: {
    getPaid: function (filters, page) {
      this.loading = true;
      this.payments.splice(0);

      const params = {};
      var url = "/Payments/";
      if (!filters) {
        filters = this.current_filters;
      } else {
        this.current_filters = filters;
      }

      if (filters) {
        var j = JSON.stringify(filters);
        params.f = j;
      }

      var num = 50;
      if (page) {
        params.page = page;
      }
      params.num = num;

      api.Manager.axios.get(url, { params: params }).then((response) => {
        this.loading = false;
        var d = response.data;
        if (d.results) {
          d = d.results;
        }
        this.payments = d;
        this.has_more = !(d.length < num);
      });
    },
    onNext() {
      this.page_no += 1;
      this.getPaid(null, this.page_no);
    },
    onPrevious() {
      this.page_no -= 1;
      if (this.page_no < 1) {
        this.page_no = 1;
      }
      this.getPaid(null, this.page_no);
    },
  },
  created: function () {
    this.getPaid();
  },
};
</script>

<template>
  <div id="BillingPaid" class="container-fluid">
    <Loader :active="loading" spinner="ring" color="#64B963" />
    <div class="row">
      <div class="col">
        <billing-filter @applyFilters="getPaid" />
      </div>

      <div class="container">
        <billing-table
          :loading="loading"
          @reloadData="getPaid"
          :has_more="has_more"
          :payments="payments"
          :page="page_no"
          @onPrevious="onPrevious"
          @onNext="onNext"
        />
      </div>
    </div>
  </div>
</template>

<style lang="scss">
select.form-control {
  font-size: 13px;
}
#BillingPaid {
  padding-top: 10px;
}
</style>
